<script>
	import { onMount } from 'svelte';
	import { Link } from 'svero';
	import Contract from '../components/Contract.svelte';
	import Expiration from '../components/Expiration.svelte';
  import { getDisplayText } from '../helpers/Contract';
  import { logout } from '../stores/Auth';
  import { contract } from '../stores/Contract';
  import PopupGarantiaAtiva from './PopupGarantiaAtiva.svelte';
  import { navigateTo } from 'svero';

	export let isTemporary = false;
	export let expiresIn = +new Date();
	export let showNav = true;
	export let showActions = true;
  export let showContract = true;
  export let showPopupGarantia = false;
  export let titleGarantia = '';
  let currentPath = window.location.pathname.split('/');
  
  function openPopupGarantia() {
    showPopupGarantia = false;
    showPopupGarantia = true;
  }

  onMount(() => {
    contract.subscribe(() => {
      if ($contract.raw) {
        titleGarantia = getDisplayText($contract.raw);
      }
    });
  });

	function goTo() {
		navigateTo('/busca-contrato');
	}
</script>

<svelte:window
  on:popstate={() => (currentPath = window.location.pathname.split('/')[1])} />

<PopupGarantiaAtiva show={showPopupGarantia} />

<header class="header">
	<div class="topbar">
		<div class="container">
			<div class="topbar__logo_contract">
				<img src="/img/logo-white.png" alt="Mercurio" class="logo" on:click={goTo}/>
				{#if isTemporary}
					<div class="temp_map">
						<b>Mapa Temporário</b>
						<img src="/img/icon-police.svg" alt="" />
					</div>
				{/if}
				{#if showContract && !isTemporary}
					<Contract />
					{#if $contract.contract}
						<button class="topbar__garantia" on:click={openPopupGarantia}>
						{#if titleGarantia.includes('Ativ')}
							<img src="/img/icon-garantia-check.svg" alt="">
						{:else}
							<img src="/img/icon-garantia-error.svg" alt="">
						{/if}
						{titleGarantia}
						</button>
					{/if}
				{/if}
			</div>
			{#if isTemporary && !!expiresIn}
				<Expiration expiresIn={expiresIn} />
			{/if}
			{#if showActions && !isTemporary}
				<div class="topbar__actions">
					<Link href="/minha-conta">
						<img src="/img/icon-user.svg" alt="Minha Conta" />
					</Link>
					<img
						class="logout"
						on:click={logout}
						src="/img/icon-logout.svg"
						alt="Sair" />
				</div>
			{/if}
		</div>
	</div>
	{#if showNav}
		<nav class="menu">
			<ul class="container">
				<li class:active={currentPath[2] == 'validar-fotos' && currentPath[3] == undefined}>
					<Link href={`/${$contract.contractNumber}/validar-fotos`}>Validar fotos</Link>
        		</li>

				{#if $contract?.raw?.Casos?.[0]?.Colisão || $contract?.raw?.Casos?.[0]?.Vidros || $contract?.raw?.Casos?.[0]?.['Colisão com Terceiros'] || $contract?.raw?.Casos?.[0]?.['Indenização Total'] || $contract?.raw?.Casos?.[0]?.['Indenização Total Terceiros']}
					<div class="linha-de-separacao"></div>
				{/if}
				
				{#if $contract?.raw?.Casos?.[0]?.Colisão}
					<li class:active={currentPath[3] == 'colisao'}>
						<Link href={`/${$contract.contractNumber}/validar-fotos/colisao/${$contract?.raw?.Casos?.[0]?.Colisão}`}>Colisão</Link>
        			</li>
				{/if}
				{#if $contract?.raw?.Casos?.[0]?.Vidros}
					<li class:active={currentPath[3] == 'vidros'}>
						<Link href={`/${$contract.contractNumber}/validar-fotos/vidros/${$contract?.raw?.Casos?.[0]?.Vidros}`}>Vidros</Link>
        			</li>
				{/if}
				{#if $contract?.raw?.Casos?.[0]?.['Colisão com Terceiros']}
					<li class:active={currentPath[3] == 'terceiros'}>
						<Link href={`/${$contract.contractNumber}/validar-fotos/terceiros/${$contract?.raw?.Casos?.[0]?.['Colisão com Terceiros']}`}>Terceiros</Link>
        			</li>
				{/if}
				{#if $contract?.raw?.Casos?.[0]?.['Indenização Total']}
					<li class:active={currentPath[3] == 'indenizacao'}>
						<Link href={`/${$contract.contractNumber}/validar-fotos/indenizacao/${$contract?.raw?.Casos?.[0]?.['Indenização Total']}`}>Indenização</Link>
        			</li>
				{/if}

				{#if $contract?.raw?.Casos?.[0]?.['Indenização Total Terceiros']}
					<li class:active={currentPath[3] == 'indenizacao-terceiros'}>
						<Link href={`/${$contract.contractNumber}/validar-fotos/indenizacao-terceiros/${$contract?.raw?.Casos?.[0]?.['Indenização Total Terceiros']}`}>Indenização Terceiros</Link>
        			</li>
				{/if}

				{#if $contract.imei}
					<div class="linha-de-separacao"></div>

					<li class:active={currentPath === 'furto-roubo'}>
						<Link href={`/${$contract.contractNumber}/furto-roubo`}>Furto e Roubo</Link>
					</li>
					<li class:active={currentPath === 'mapa-veiculo'}>
						<Link href={`/${$contract.contractNumber}/mapa-veiculo`}>Mapa do Veículo</Link>
					</li>
					<li class:active={currentPath === 'eventos'}>
						<Link href={`/${$contract.contractNumber}/eventos`}>Eventos</Link>
					</li>
					<!-- <li class:active={currentPath === 'eventos/nao-associados'}>
						<Link href={`/${$contract.contractNumber}/eventos/nao-associados`}>Dispositivos não associados</Link>
					</li> -->
				{/if}
			</ul>
		</nav>
	{/if}
</header>

<style>
	.linha-de-separacao {
		border-left: 1px solid var(--color-green-dark);
		height: 30px;
		display: inline-flex;
		align-items: center;
		margin-left: -10px;
		margin-right: 25px;
		margin-top: 4px;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: var(--z-5);
		background: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	.logout {
		cursor: pointer;
	}

	.topbar {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 16px;
		background: var(--color-blue);
	}

	.topbar .logo {
    width: 132px;
    user-select: none;
	cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .topbar .logo {
      width: 90px;
    }
  }

	.topbar .container {
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.topbar__logo_contract {
		display: flex;
		align-items: center;
	}

	.topbar__actions {
		display: flex;
		align-items: center;
	}

	.topbar__actions img {
		margin-left: 15px;
	}

	.menu {
		height: 35px;
		background: var(--color-green-15);
	}

	.menu ul {
		display: flex;
	}

	.menu ul li :global(a) {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		padding: 5px 0px;
		margin-right: 40px;
		font-size: 14px;
		color: var(--color-green-dark);
		box-shadow: 0 0 0 var(--color-green-dark) inset;
		transition: all 0.2s linear;
	}

	.menu ul li :global(a:hover) {
		box-shadow: 0 -2px 0 var(--color-green-dark) inset;
	}

	.menu ul li.active :global(a) {
		box-shadow: 0 -2px 0 var(--color-green-dark) inset;
	}

	.temp_map {
		margin-left: 20px;
		display: flex;
		align-items: center;
		color: #fff;
  }
  
  @media screen and (max-width: 600px) {
    .temp_map {
      display: none;
    }
  }

	.temp_map img {
		margin-left: 10px;
  }
  
  .topbar__garantia {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
  }

  .topbar__garantia img {
    width: 20px;
    margin-right: 10px;
  }
</style>
