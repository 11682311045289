<script>
    import imageCompression from 'browser-image-compression';
	import Loader from './Loader.svelte';
	import Popup from './Popup.svelte';
    import Heading from '../components/Heading.svelte';
    import PicturesSinister from '../services/PicturesSinister';
    import Pictures from '../services/Pictures.js';
    import Contract from '../services/Contract.js';

    let isPopupOpen = false;
    let isLoading = false;
    let contratoNaoEncontrado = false;

    let nomeArquivo = "";

    let msgAlerta = "";
    let msgAlertaFotos = "";

    let categoria = "";
    let tipoSelecionado = "";
    let numeroContrato = "";

    let formato = "";
    let base64 = "";

    let listaDeCasos = [];
    let tiposDeCasos = [];

    let tipos_visiveis_e_obrigatorios = [];
    let tiposDisponiveis = [];

    let statusFotos = "";

    let imagePreview = "";

    async function updateCategoria(e) {
        tiposDisponiveis = [];
        msgAlerta = "";
        msgAlertaFotos = "";
        categoria = e.target.value;

        if(categoria == 'Onboarding' || categoria == 'Troca de Veículo'){
            isLoading = true;
            const result = await Pictures.getPictures(numeroContrato)
            if(result){
                isLoading = false;
            }
            tipos_visiveis_e_obrigatorios = result.filter(a => a.TipoFoto.Visivel && a.TipoFoto.Obrigatorio);
            tiposDisponiveis = tipos_visiveis_e_obrigatorios.filter(a => a.Foto === null || a.Foto.Aprovacao == false);

            if(tiposDisponiveis.length < 1){
                msgAlertaFotos = 'Todas as fotos enviadas';
            }

        }else if(categoria == 'Colisão com Terceiros'){
            isLoading = true;
            const result = await PicturesSinister.getPicturesDocumentosAuxiliares('terceiros', listaDeCasos[categoria])
            if(result){
                isLoading = false;
            }
            tipos_visiveis_e_obrigatorios = result.filter(a => a.TipoFoto.Visivel && a.TipoFoto.Obrigatorio);
            tiposDisponiveis = tipos_visiveis_e_obrigatorios.filter(a => a.Foto == null);

            if(tiposDisponiveis.length < 1){
                msgAlertaFotos = 'Todas as fotos enviadas';
            }

        }else if(categoria == 'Indenização Total'){
            isLoading = true;
            const result = await PicturesSinister.getPicturesDocumentosAuxiliares('indenizacao', listaDeCasos[categoria])
            if(result){
                isLoading = false;
            }
            tipos_visiveis_e_obrigatorios = result.filter(a => a.TipoFoto.Visivel && a.TipoFoto.Obrigatorio);
            tiposDisponiveis = tipos_visiveis_e_obrigatorios.filter(a => a.Foto == null);

            if(tiposDisponiveis.length < 1){
                msgAlertaFotos = 'Todas as fotos enviadas';
            }
        }else if(categoria == 'Colisão' || categoria == 'Vidros'){
            isLoading = true;
            const result = await PicturesSinister.getPictures(numeroContrato, listaDeCasos[categoria])
            if(result){
                isLoading = false;
            }
            tipos_visiveis_e_obrigatorios = result.filter(a => a.TipoFoto.Visivel && a.TipoFoto.Obrigatorio);
            tiposDisponiveis = tipos_visiveis_e_obrigatorios.filter(a => a.Foto == null);

            if(tiposDisponiveis.length < 1){
                msgAlertaFotos = 'Todas as fotos enviadas';
            }

        }else if(categoria == 'Indenização Total Terceiros'){
            isLoading = true;
            const result = await PicturesSinister.getPicturesDocumentosAuxiliares('indenizacao-terceiros', listaDeCasos[categoria])
            if(result){
                isLoading = false;
            }
            tipos_visiveis_e_obrigatorios = result.filter(a => a.TipoFoto.Visivel && a.TipoFoto.Obrigatorio);
            tiposDisponiveis = tipos_visiveis_e_obrigatorios.filter(a => a.Foto == null);

            if(tiposDisponiveis.length < 1){
                msgAlertaFotos = 'Todas as fotos enviadas';
            }

        }
    }

    function updateTipo(e) {
        msgAlerta = "";
        tipoSelecionado = e.target.value;
    }

    function updateContrato(e){
        msgAlerta = "";
        numeroContrato = e.target.value;
    }

    function handleInputFile(e){
        const file = e.target.files[0];
        msgAlerta = "";
        nomeArquivo = file.name;
        isLoading = true;

        if(!file) {
            console.log("Nenhum arquivo selecionado.");
            return;
        }

        if(e.target.files[0].type.split("/")[1] == "pdf") {
            converteParaBase64(e.target.files[0]);
            formato = 'pdf';
        }else{
            comprimir(e);
            formato = 'jpg';
        }

        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = () => {
                imagePreview = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async function handleSubmit(e){
        e.preventDefault();

        if(categoria == '' || tipoSelecionado == '' || base64 == ''){
            msgAlerta = 'Complete todos os campos'
        }else{
            putUpload();
        }
    }

    function limpaPreview(){
        nomeArquivo = "";
        base64 = "";
        msgAlerta = "";
        imagePreview = "";
    }

    const converteParaBase64 = (file) => {
      return new Promise((resolve, reject) => {
        if (file) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);

          let base64SemOInicio = "";

          fileReader.onload = () => {
            if (fileReader.result) {
              resolve(fileReader.result);
              base64SemOInicio = fileReader.result.slice(28);
              base64 = base64SemOInicio;
              isLoading = false;
            } else {
              reject("Erro ao ler o arquivo.");
            }
          };

          fileReader.onerror = (error) => {
            reject(error);
            isLoading = false;
          };
        }
      });
    };

    const comprimir = async (event) => {
        let base64string = "";

        const imageFile = event.target.files[0];

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            base64string = await converteParaBase64(compressedFile);
            const ultimaLetraDoFormato = base64string.slice(11, 14)[2];

            if(ultimaLetraDoFormato == 'e'){
                base64 = base64string.slice(23);
            }else{
                base64 = base64string.slice(22);
            }
        }catch (error) {
            console.error('Erro ao comprimir a imagem:', error);
        }
    };

    async function putUpload(){
        if(categoria == 'Colisão com Terceiros'){
            isLoading = true;
            const result = await PicturesSinister.uploadPictureDocumentosAuxiliares(base64, 'terceiros', tipoSelecionado, formato, listaDeCasos[categoria]);

            if(result){
                isLoading = false;
                if(result.error) {
                    isLoading = false;
                    console.log('Erro de Servidor', result.message);
                    return;
                }else if(result.ok){
                    isPopupOpen = false;
                    isPopupOpen = true;
                    limpaAposEnviar();
                }
            }else{
                isLoading = false;
            }
        }else if(categoria == 'Indenização Total'){
            isLoading = true;
            const result = await PicturesSinister.uploadPictureDocumentosAuxiliares(base64, 'indenizacao', tipoSelecionado, formato, listaDeCasos[categoria]);

            if(result){
                isLoading = false;
                if(result.error) {
                    isLoading = false;
                    console.log('Erro de Servidor', result.message);
                    return;
                }else if(result.ok){
                    isPopupOpen = false;
                    isPopupOpen = true;
                    limpaAposEnviar();
                }
            }else{
                isLoading = false;
            }
        }else if(categoria == 'Onboarding' || categoria == 'Troca de Veículo'){
            isLoading = true;
            const result = await Pictures.uploadPicture(base64, numeroContrato, tipoSelecionado, formato);
    
            if(result){
                isLoading = false;
                if(result.error) {
                    isLoading = false;
                    console.log('Erro de Servidor', result.message);
                    return;
                }else if(result.ok){
                    isPopupOpen = false;
                    isPopupOpen = true;
                    limpaAposEnviar();
                }
            }else{
                isLoading = false;
            }
        }else if(categoria == 'Colisão' || categoria == 'Vidros'){
            isLoading = true;
            const result = await PicturesSinister.uploadPicture(base64, numeroContrato, tipoSelecionado, formato, listaDeCasos[categoria]);
    
            if(result){
                isLoading = false;
                if(result.error) {
                    isLoading = false;
                    console.log('Erro de Servidor', result.message);
                    return;
                }else if(result.ok){
                    isPopupOpen = false;
                    isPopupOpen = true;
                    limpaAposEnviar();
                }
            }else{
                isLoading = false;
            }
        }else if(categoria == 'Indenização Total Terceiros'){
            isLoading = true;
            const result = await PicturesSinister.uploadPictureDocumentosAuxiliares(base64, 'indenizacao-terceiros', tipoSelecionado, formato, listaDeCasos[categoria]);

            if(result){
                isLoading = false;
                if(result.error) {
                    isLoading = false;
                    console.log('Erro de Servidor', result.message);
                    return;
                }else if(result.ok){
                    isPopupOpen = false;
                    isPopupOpen = true;
                    limpaAposEnviar();
                }
            }else{
                isLoading = false;
            }
        }
    }
    
    async function handlePesquisarContrato(){
        if(numeroContrato.length > 15){
            isLoading = true;
            const dadosDoContrato = await Contract.getContract(numeroContrato);
            if(dadosDoContrato){
                isLoading = false;
                statusFotos = dadosDoContrato.StatusFotos;
                listaDeCasos = dadosDoContrato.Casos[0];
                if (Array.isArray(listaDeCasos) && listaDeCasos.length > 0) {
                    tiposDeCasos = Object.keys(listaDeCasos);
                } else {
                    tiposDeCasos = [];
                }

                if(!tiposDeCasos.includes('Onboarding')){
                    tiposDeCasos = [...tiposDeCasos, 'Onboarding'];
                }
            }else{
                isLoading = false;
                contratoNaoEncontrado = true;
            }
        }
    }

    function limpaAposEnviar(){
        categoria = '';
        tipoSelecionado = '';
        nomeArquivo = '';
        formato = "";
        base64 = "";
        tiposDisponiveis = [];
        imagePreview = "";
    }
</script>

<Loader visible={isLoading} />

<main>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="pai">
            <Heading main="Enviar Fotos" />

            <div class="painel">
                <form on:submit={handleSubmit}>
                    <div class="teste">

                        <div class="teste2">
                            <label for="contrato">Contrato</label>
                            <input on:change={updateContrato} type="text" class="inputNumeroContrato" id="contrato" maxlength="22">
                            {#if tiposDeCasos.length == 0}
                                <span on:click={handlePesquisarContrato}>Pesquisar</span>
                            {/if}
                        </div>

                        {#if (tiposDeCasos.length > 0) || statusFotos == 'alguma-pendente-upload' || statusFotos == 'alguma-reprovada' || statusFotos == 'todas-pendente-upload'}
                            <div class="teste2">
                                <label for="csv_file">
                                    Categoria
                                </label>

                                <select on:change={updateCategoria} bind:value={categoria}>
                                    <option value="">--</option>
                                    {#each tiposDeCasos as caso}
                                        <option value="{caso}">{caso}</option>
                                    {/each}
                                </select>
                            </div>
                        {/if}

                        {#if tiposDisponiveis.length > 0}
                            <div class="teste2">
                                <label for="csv_file">
                                    Tipo
                                </label>
                                <select class="selectTipo" on:change={updateTipo}>
                                    <option value="">--</option>
                                    {#each tiposDisponiveis as tipo}
                                        <option value={tipo.TipoFoto.Id}>{tipo.TipoFoto.Titulo}</option>
                                    {/each}
                                </select>
                            </div>
                        {/if}
                        {#if msgAlertaFotos.length > 0}
                            <div class="msgAlertaFotos">
                                <h1>{msgAlertaFotos}</h1>
                            </div>
                        {/if}
                    </div>

                    <div>
                        {#if imagePreview}
                            <div on:click={limpaPreview} for="arquivo" class="imagePreviewContainer" aria-label="Excluir" data-balloon-pos="down">
                                <img src={imagePreview} alt="Prévia da imagem" class="imagePreview" />
                            </div>
                        {:else}
                            {#if tipoSelecionado.length > 0}
                                <label class="labelInputFile" for="arquivo">Selecionar arquivo</label>
                                <input
                                    on:change={handleInputFile}
                                    on:click={(e) => (e.target.value = null)}
                                    class="inputFile"
                                    type="file"
                                    name="arquivo"
                                    id="arquivo"
                                    accept="image/jpg, application/pdf"
                                >
                            {/if}
                        {/if}
                    </div>

                    {#if base64.length>0 && categoria.length>0 && tipoSelecionado.length>0}
                        <div class="divSubmit">
                            <input class="btn altura" type="submit" value="Enviar">
                        </div>
                    {/if}

                    {#if msgAlerta.length > 0}
                        <div class="msgAlerta">
                            <h1>{msgAlerta}</h1>
                        </div>
                    {/if}
                </form>
            </div>
        </div>
</main>

<Popup
	visible={isPopupOpen}
	title="Foto enviada com Sucesso!"
	message="" />

<Popup
	visible={contratoNaoEncontrado}
	title="Contrato não encontrado"
	message="" />

<style>
    .imagePreviewContainer {
        margin-top: 10px;
        display: flex;
        width: fit-content;
        margin: 0 auto;
    }

    .imagePreview {
        max-width: 100%;
        max-height: 250px;
        border: 1px solid #ccc;
        border-radius: 10px;
    }

    .imagePreview:hover{
        opacity: 0.5;
        content: "teste";
    }

    .msgAlertaFotos{
        color: red;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        margin-top: 23.5px;
    }

    .teste2 span{
        border-radius: 3px;
        padding: 6px;
        width: 178px;
        font-size: 10px;
        background: #5a78ff;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        transition: .5s;
        border: 1px solid #333;
        font-weight: bold;
    }

    .teste2 span:hover{
        color: #333;
        background: rgba(57, 198, 57, 0.1);
    }

    .labelInputFile{
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 178px;
        font-size: 10px;
        background: rgba(57, 198, 57, 0.3);
        color: black;
        text-transform: uppercase;
        display: block;
        margin-top: 10px;
        text-align: center;
        cursor: pointer;
        transition: .5s;
        border: 1px solid #333;
        font-weight: bold;
    }

    .labelInputFile:hover{
        color: #333;
        background: rgba(57, 198, 57, 0.1);
    }

    .inputFile{
        display: none;
    }

    .altura{
        height: 35px;
    }

    input{
        height: 30px;
        width: 178px;
        text-align: center;
    }

    select{
        width: 178px;
        height: 30px;
        cursor: pointer;
        text-align: center;
    }

    .divSubmit{
        text-align: center;
        margin-top: 25px;
    }

    .teste2{
        margin-bottom: 30px;
        margin-right: 70px;
    }

    .teste{
        display: flex;
    }

    .pai{
        max-width: 1004px;
        margin: 0 auto;
    }

    .painel{
        background: #fff;
        border-radius: 5px;
        border: 1px solid var(--color-black-20);
        display: flex;
    }

    form{
        width: 100%;
        padding: 11px 13px;
    }

    form label{
        display: block;
        font-size: 11px;
        margin-bottom: 5px;
        margin-top: 10px;
        font-weight: 400;
    }

    .btn{
        cursor: pointer;
        width: 5px;
    }

    .msgAlerta{
        background-color: red;
        color: white;
        font-weight: bold;
        text-align: center;
        padding: 10px;
        margin-top: 15px;
    }
</style>