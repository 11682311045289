<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import Popup from './Popup.svelte';
  import PopupHelper from '../helpers/Popup';
  import Intense from "@tholman/intense-images";
  import Loader from './Loader.svelte';

  const dispatch = createEventDispatcher();

  export let pictures = [];
  export let tipoCaso = "";

  let imgRef;
  
  let isApprovalVisible = false;
  let isReprovalVisible = false;

  let selectedReason = "";

  function handleReasonSelected(event) {
    selectedReason = event.detail;
  }

  let picturesMap = false;

  let currentPicture = false;
  let currentName = false;

  let currentPictureWidth = 0;
  let currentPictureHeight = 0;
  let currentPictureOrientation = 'vertical'; // vertical|horizontal
  let currentPictureVirtualOrientation = 'vertical';
  let currentPictureRotation = 0;
  let isLoadingImage = true;

  function setCurrentPicture(name) {
    if (name === currentName) {
      return;
    }

    window['currentPictureName'] = name;
    isLoadingImage = true;
    currentName = name;
    currentPicture = picturesMap[name];

    selectedReason = "";
  }

  function openApproval() {
    isApprovalVisible = false;
    isApprovalVisible = true;
  }

  function openReproval() {
    isReprovalVisible = false;
    isReprovalVisible = true;
  }

  function handleApproval(data) {
    if (!data.detail.result) {
      return;
    }

    currentPicture.approved = true;
    picturesMap[currentPicture.type].approved = true;
    picturesMap = {...picturesMap};

    dispatch('approval', {
      id: currentPicture.type
    });
  }

  function handleReproval(data) {
    if (!data.detail.result) {
      return;
    }
    
    currentPicture.approved = false;
    picturesMap[currentPicture.type].approved = false;
    picturesMap = {...picturesMap};

    dispatch('reproval', {
      id: currentPicture.type,
      selectedReason: selectedReason
    });
  }

  function handleResetAll() {
    window['currentPictureName'] = false;
    dispatch('resetAll');
  }

  function handleResetCurrent() {
    const currentPic = pictures.find(picture => picture.TipoFoto.Id === currentName);

    if (!currentPic) {
      PopupHelper.alert("Nenhuma foto selecionada", "Selecione a foto que deseja apagar");
      return;
    }

    if (currentPic.Foto === null) {
      PopupHelper.alert("Sem foto para apagar.", "Essa foto ainda não foi enviada pelo usuário, e portanto não pode ser resetada.");
      return;
    }

    dispatch('resetCurrent', currentName);
  }

  function isPortrait(img) {
    const w = img.naturalWidth || img.width;
    const h = img.naturalHeight || img.height;

    return h > w;
  }

  function computePdfData(e) {
    const img = e.target;
    const vertical = isPortrait(img);

    isLoadingImage = true;

    currentPictureWidth = img.naturalWidth || img.width;
    currentPictureHeight = img.naturalHeight || img.height;
    currentPictureOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureVirtualOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureRotation = 0;

    setTimeout(() => {
      isLoadingImage = false;
    }, 60);
  }

  function computeImageData(e) {
    const img = e.target;
    const vertical = isPortrait(img);

    isLoadingImage = true;

    currentPictureWidth = img.naturalWidth || img.width;
    currentPictureHeight = img.naturalHeight || img.height;
    currentPictureOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureVirtualOrientation = vertical ? 'vertical' : 'horizontal';
    currentPictureRotation = 0;

    setTimeout(() => {
      isLoadingImage = false;
      handleImgZoom();
    }, 60);
  }

  function rotateLeft() {
    const vertical = currentPictureVirtualOrientation === 'vertical';
    currentPictureVirtualOrientation = vertical ? 'horizontal' : 'vertical';
    currentPictureRotation -= 90;
  }

  function rotateRight() {
    const vertical = currentPictureVirtualOrientation === 'vertical';
    currentPictureVirtualOrientation = vertical ? 'horizontal' : 'vertical';
    currentPictureRotation += 90;
  }

  function nextPhoto() {
    const photoNames = Object.keys(picturesMap);
    const currentPhotoIndex = photoNames.findIndex(photo => photo === currentName);

    // Caso nao seja a ultima foto, avanca
    if (currentPhotoIndex < photoNames.length - 1) {
      setCurrentPicture(photoNames[currentPhotoIndex + 1]);
    }
  }

  function prevPhoto() {
    const photoNames = Object.keys(picturesMap);
    const currentPhotoIndex = photoNames.findIndex(photo => photo === currentName);

    // Caso não seja a primeira foto, retorna
    if (currentPhotoIndex > 0) {
      setCurrentPicture(photoNames[currentPhotoIndex - 1]);
    }
  }

  function handleImgZoom() {
    if (!imgRef) {
      setTimeout(() => handleImgZoom(), 200);
      return;
    }
    Intense(imgRef);
  }

  onMount(() => {
    if(tipoCaso == 'terceiros'){
      picturesMap = {
        'cnh': pictures.find(e => e.type === 'cnh'),
        'crlv': pictures.find(e => e.type === 'crlv'),
        'boletim': pictures.find(e => e.type === 'boletim'),
        'veiculo-dianteira-direita': pictures.find(e => e.type === 'veiculo-dianteira-direita'),
        'veiculo-traseira-direita': pictures.find(e => e.type === 'veiculo-traseira-direita'),
        'dano-primeira-foto': pictures.find(e => e.type === 'dano-primeira-foto'),
        'dano-segunda-foto': pictures.find(e => e.type === 'dano-segunda-foto'),
        'orcamento': pictures.find(e => e.type === 'orcamento')
      }
    }else if(tipoCaso == 'indenizacao' || tipoCaso == 'indenizacao-terceiros'){
      picturesMap = {
        'crv-atpve': pictures.find(e => e.type === 'crv-atpve'),
        'procuracao-publica': pictures.find(e => e.type === 'procuracao-publica'),
        'formulario-liquidacao-sinistro': pictures.find(e => e.type === 'formulario-liquidacao-sinistro'),
        'cnh-rg-cpf': pictures.find(e => e.type === 'cnh-rg-cpf'),
        'cartao-conta': pictures.find(e => e.type === 'cartao-conta'),
        'comprovante-endereco': pictures.find(e => e.type === 'comprovante-endereco'),
        'manual-chaves': pictures.find(e => e.type === 'manual-chaves'),
        'contrato-financiamento': pictures.find(e => e.type === 'contrato-financiamento'),
      }
    }
    else{
      picturesMap = {
        'cnh': pictures.find(e => e.type === 'cnh'),
        'clrv': pictures.find(e => e.type === 'clrv'),
        'boletim': pictures.find(e => e.type === 'boletim'),
        'dano-primeira-foto': pictures.find(e => e.type === 'dano-primeira-foto'),
        'dano-segunda-foto': pictures.find(e => e.type === 'dano-segunda-foto'),
        'dano-terceira-foto': pictures.find(e => e.type === 'dano-terceira-foto'),
        'veiculo-dianteira-direita': pictures.find(e => e.type === 'veiculo-dianteira-direita'),
        'veiculo-dianteira-esquerda': pictures.find(e => e.type === 'veiculo-dianteira-esquerda'),
        'veiculo-traseira-direita': pictures.find(e => e.type === 'veiculo-traseira-direita'),
        'veiculo-traseira-esquerda': pictures.find(e => e.type === 'veiculo-traseira-esquerda'),
        'orcamento': pictures.find(e => e.type === 'orcamento')
      }
    }

    // olhar este trecho
    setTimeout(() => {
      if(tipoCaso == 'terceiros'){
        setCurrentPicture(window['currentPictureName'] || "cnh");
      }else if(tipoCaso == 'indenizacao' || tipoCaso == 'indenizacao-terceiros'){
        setCurrentPicture(window['currentPictureName'] || "crv-atpve");
      }else{
        setCurrentPicture(window['currentPictureName'] || "rg-cnh-frente");
      }
    }, 100);
  });

  function converteTimestamp(timestamp) {
    var data = new Date(timestamp * 1000);

    var dia = data.getDate();
    var mes = data.getMonth() + 1;
    var ano = data.getFullYear();

    var horas = data.getHours();
    var minutos = data.getMinutes();

    if (dia < 10) dia = '0' + dia;
    if (mes < 10) mes = '0' + mes;
    if (horas < 10) horas = '0' + horas;
    if (minutos < 10) minutos = '0' + minutos;

    var dataFormatada = dia + '/' + mes + '/' + ano + ' - ' + horas + ':' + minutos;

    return dataFormatada;
  }
</script>

<div class="check-carousel">
  <button on:click={handleResetAll} class="check-carousel__reset" aria-label="Apagar todas as fotos" data-balloon-pos="left">
    <img src="/img/icon-delete-white.svg" alt="">
  </button>


  <div class="check-carousel__legends">
    <div class="check-carousel__action">
      <button on:click={openReproval} disabled={typeof currentPicture?.approved === "boolean" || !currentPicture?.hasPic} class="check-carousel__action__btn check-carousel__action__btn--reprove" aria-label="Reprovar foto." data-balloon-pos="up">
        <img src="/img/icon-photo-close-white.svg" alt="">
      </button>

      <button on:click={handleResetCurrent} disabled={!currentPicture?.hasPic} class="check-carousel__action__btn" aria-label="Apagar foto." data-balloon-pos="up">
        <img src="/img/icon-delete-white.svg" alt="">
      </button>

      <button on:click={openApproval} disabled={typeof currentPicture?.approved === "boolean" || !currentPicture?.hasPic} class="check-carousel__action__btn check-carousel__action__btn--approve" aria-label="Aprovar foto." data-balloon-pos="up">
        <img src="/img/icon-photo-check-white.svg" alt="">
      </button>
    </div>
    <div class="rotate_actions">
      <button on:click={prevPhoto} aria-label="Voltar foto." data-balloon-pos="left">
        <img src="/img/icon-arrow-left-white.svg" alt="">
      </button>
      <button on:click={rotateLeft} aria-label="Girar foto para esquerda." data-balloon-pos="down">
        <img src="/img/icon-rotate-left.svg" alt="">
      </button>
      <button on:click={rotateRight} aria-label="Girar foto para direita." data-balloon-pos="down">
        <img src="/img/icon-rotate-right.svg" alt="">
      </button>
      <button on:click={nextPhoto} aria-label="Próxima foto." data-balloon-pos="right">
        <img src="/img/icon-arrow-right-white.svg" alt="">
      </button>
    </div>
  </div>

  {#if currentPicture !== false}
    {#if currentPicture?.hasPic}
      {#if currentPicture?.url.includes(currentPicture?.type + '.jpg')}
        <div class="check-carousel__image__wrapper">
        <img src={currentPicture?.url} alt="" bind:this={imgRef} class="check-carousel__image" class:no--animation={isLoadingImage} class:check-carousel__image--vertical={currentPictureVirtualOrientation === 'vertical'} style={`transform: rotate(${currentPictureRotation}deg)`} on:load={computeImageData}>
        <Loader local={true} absolute={true} visible={isLoadingImage} />
      </div>
      {:else}
        <embed src={currentPicture?.url} type="application/pdf" class="check-carousel__pdf" class:no--animation={isLoadingImage} class:check-carousel__image--vertical={currentPictureVirtualOrientation === 'vertical'} style={`transform: rotate(${currentPictureRotation}deg)`} on:load={computePdfData}/>
        <Loader local={true} absolute={true} visible={isLoadingImage} />
      {/if}
    {:else}
      <div class="check-carousel__no_pic">Usuário ainda não enviou imagem.</div>
    {/if}
  {/if}

  {#if picturesMap}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="check-carousel__actions">
      {#each pictures.filter(a => a.TipoFoto.Visivel) as picture}
        <div class="check-carousel__actions__action" aria-label="{picture?.Foto?.Aprovacao ? "Aprovada" : "Reprovada"} por: {picture?.Foto?.UsuarioAprovacao} - {converteTimestamp(picture?.Foto?.DataHoraAprovacao)}" data-balloon-hidden={picture.Foto?.Aprovacao == null} data-balloon-pos="up-left" class:check-carousel__actions__action--needs-approval={picture.Foto != null && picture.Foto?.Aprovacao == null} class:check-carousel__actions__action--approved={picture.Foto?.Aprovacao == true} class:check-carousel__actions__action--reproved={picture.Foto?.Aprovacao === false} class:check-carousel__actions__action--active={currentName === picture.TipoFoto.Id} on:click={() => setCurrentPicture(picture.TipoFoto.Id)}>
          <div class="badge"></div>
          <span>{picture.TipoFoto.Titulo}</span>
        </div>
      {/each}
    </div>
  {/if}
</div>

<Popup visible={isApprovalVisible} type="prompt" on:prompt={handleApproval} title="Aprovar Foto?" message="Você está prestes a aprovar uma foto, tem certeza disso?" okLabel="Sim, Aprovar." />
<Popup visible={isReprovalVisible} type="promptMotivo" on:prompt={handleReproval} title="Reprovar Foto?" message="Selecione o motivo da reprovação" okLabel="Reprovar" on:reasonSelected={handleReasonSelected} tipoCaso={tipoCaso}/>

<style>
  .check-carousel {
    position: fixed;
    top: 85px;
    left: 0;
    width: 100%;
    height: calc(100% - 85px - 62px);
    display: flex;
    padding-bottom: 90px;
    z-index: var(--z-1);
    background: var(--color-black-85);
  }

  .check-carousel__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    background: var(--color-black-70);
    border-top: 1px solid var(--color-black-70);
  }

  .check-carousel__actions__action {
    position: relative;
    flex: 1;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s linear;
  }

  .check-carousel__actions__action:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .check-carousel__actions__action:active {
    background: rgba(255, 255, 255, 0.4);
  }

  .check-carousel__actions__action--active {
    background: #fff !important;
  }

  .check-carousel__actions__action--approved .badge {
    position: absolute;
    background: url(/img/icon-photo-check.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--reproved .badge {
    position: absolute;
    background: url(/img/icon-photo-close.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 100% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--needs-approval .badge {
    position: absolute;
    background: url(/img/icon-photo-warn.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 80% auto;
    border-radius: 50%;
    left: 10px;
    top: 10px;
  }

  .check-carousel__actions__action--active span {
    color: var(--color-black) !important;
  }

  .check-carousel__actions__action:not(:last-child) {
    border-right: 1px solid var(--color-black-70);
  }

  .check-carousel__actions__action span {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
  }

  .check-carousel__image {
    max-width: 100%;
    max-height: 100%;
    background: #fff;
    transition: all 0.2s ease-in-out;
    cursor: zoom-in;
  }

  .check-carousel__pdf {
    width: 100%;
    max-height: 100%;
    background: #fff;
    transition: all 0.2s ease-in-out;
    cursor: zoom-in;
  }

  .check-carousel__image.check-carousel__image--vertical {
    max-width: calc(100vh - 260px);
    max-height: 100%;
  }

  .check-carousel__action {
    display: flex;
    justify-content: space-between;
  }

  .check-carousel__action__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    padding: 0;
    background: rgba(0,0,0,0.8);
    border: none;
    transition: all 0.2s linear;
    opacity: 0.8;
    cursor: pointer;
  }

  .check-carousel__action__btn:hover {
    opacity: 1;
  }

  .check-carousel__action__btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .check-carousel__action__btn--approve {
    background: var(--color-status-success);
  }

  .check-carousel__action__btn--reprove {
    background: var(--color-status-error);
  }

  .check-carousel__reset {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    background: var(--color-status-error);
    border-radius: 50%;
    border: none;
  }

  .check-carousel__legends {
    position: absolute;
    left: 50%;
    bottom: 100px;
    z-index: var(--z-6);
    padding: 10px;
    background: rgb(53, 53, 53, 0.9);
    border-radius: 10px;
    transform: translateX(-50%);
  }

  .check-carousel__no_pic {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .check-carousel__image__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-carousel__image__wrapper :global(.loader-wrapper) {
    z-index: var(--z-5);
  }

  .no--animation {
    transition: none !important;
  }

  .rotate_actions {
    display: flex;
    justify-content: space-around;
    padding: 10px 0 0 0;
    border-top: 1px solid rgba(255,255,255,0.2);
    margin-top: 10px;
  }

  .rotate_actions button {
    background: rgba(0,0,0,0.8);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    opacity: 0.8;
  }

  .rotate_actions button:hover {
    opacity: 1;
  }

  .rotate_actions button:active {
    transform: scale(0.95);
  }
</style>